import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";

import { Bio } from "../../02_molecules/Bio";
import { TagList } from "../../02_molecules/TagList";
import { Layout } from "../../Layout";
import { Header } from "src/components/03_organisms/Header";
import { Footer } from "src/components/03_organisms/Footer";
import { DescItem } from "src/components/02_molecules/DescItem";

import ogPic from "src/assets/images/ogp.png";
import styles from "./style.module.css";

const TagTemplate = (props) => {
  const { tag } = props.pageContext;
  const { edges, totalCount } = props.data.allMarkdownRemark;

  const siteMetaData = props.data.site.siteMetadata;
  const tagHeader = `${tag} に関する記事 （${totalCount}件）`;
  const pageUrl = siteMetaData.siteUrl + tag;

  return (
    <Layout>
      <Header isHome={false} />
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        meta={[
          {
            name: "description",
            content: tagHeader + siteMetaData.siteDescription,
          },
          // { name: 'keywords', content: siteMetaData.title + ',' +siteMetadata.keywords},
          { property: "og:title", content: tagHeader + siteMetaData.title },
          {
            property: "og:description",
            content: tagHeader + siteMetaData.siteDescription,
          },
          { property: "og:url", content: pageUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: siteMetaData.title },
          { property: "twitter:card", content: "summary_large_image" },
          { property: "twitter:site", content: siteMetaData.twitterUsername },
          // { property: 'google-site-verification', content: siteMetaData.googleSiteVer},
          { property: "og:image", content: siteMetaData.siteUrl + ogPic },
        ]}
        title={tagHeader + siteMetaData.title}>
        <script
          data-ad-client="ca-pub-8068277400499023"
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <h1 className={styles.tagTitle}>{tagHeader}</h1>

      <div className={styles.flexRoot}>
        {edges.map(({ node }, index) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <DescItem
              slug={node.fields.slug}
              title={title}
              ogpimage={node.frontmatter.ogpimage}
              excerpt={node.excerpt}
              date={node.frontmatter.date}
            />
          );
        })}
      </div>
      <TagList />
      <Bio />
      <Footer />
    </Layout>
  );
};

export default TagTemplate;

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            ogpimage
          }
          fields {
            slug
          }
          excerpt(truncate: true)
        }
      }
    }
    site: site {
      siteMetadata {
        title
        description
        siteUrl
        twitterUsername
      }
    }
    tags: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
